<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">{{ $t("labels.employee") }}</span>
      <v-spacer></v-spacer>
      <v-btn
        small
        color="success"
        outlined
        @click="showDialogAddEmployee"
        class="mr-2"
      >
        {{ $t("labels.add") }}
      </v-btn>
      <v-btn small color="error" outlined @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-simple-table class="table-padding-2">
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                {{ $t("labels.employee") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.import_1") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.return_1") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.finish_stowing") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(emp, key) in items" :key="`d_${key}`">
              <td>{{ emp.employee_name }}</td>
              <td class="text-center">
                <v-icon v-if="emp.is_receipt">mdi-check</v-icon>
              </td>
              <td class="text-center">
                <v-icon v-if="emp.is_return_receipt">mdi-check</v-icon>
              </td>
              <td class="text-center">
                <v-icon v-if="emp.is_stowing || emp.is_return_stowing"
                  >mdi-check</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog v-model="dialogAddEmployee" persistent max-width="400px">
      <FindGoodsAddEmployee
        v-if="dialogAddEmployee"
        :id-warehouse="item.id_warehouse"
        :id-find-goods="item.id"
        :processing-employees="items"
        @closeDialog="hideDialogAddEmployee"
        @refreshData="getDetails"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { formatDateTime } from "@/libs/helpers";
export default {
  name: "FindGoodsDetail",
  components: {
    FindGoodsAddEmployee: () =>
      import("@/components/goods/FindGoodsAddEmployee"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    items: [],
    dialogAddEmployee: false,
  }),
  mounted() {
    this.getDetails();
  },
  methods: {
    formatDateTime,
    closeDialog() {
      this.$emit("closeDialog");
    },
    async getDetails() {
      const { data } = await httpClient.post("/find-goods/v1/manager-detail", {
        id_find_goods: this.item.id,
      });
      this.items = [...data];
    },
    showDialogAddEmployee() {
      this.dialogAddEmployee = true;
    },
    hideDialogAddEmployee() {
      this.dialogAddEmployee = false;
    },
  },
};
</script>

<style scoped></style>
